.navbar.scroll {
	background-color: $body-back;
}

#nav-acc-dropdown.dropdown-toggle::after {
	display: none;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: unset !important;
}
.profile-pill {
	padding: 0px 10px 0px 0px;
	width: 75px;
	height: 40px;
	position: relative;

	background: #ffffff;
	box-shadow: 0px 1px 5px #dddddd;
	border-radius: 100px;

	.profile-wrapper {
		margin-left: -0.5px;
	}

	.down-carrot-wrapper {
		position: absolute;
		top: 10px;
		right: 13px;
	}
}

.mask-spacing {
	display: block;
	height: 98px;
	width: 100%;
	z-index: -1;
	position: relative;
	top: 0;
	background-color: $body-back;
}

#desktop-navbar {
	top: 27.5px;

	height: 71px !important;
	box-shadow: 0px 1px 0px #dddddd;
	.navbar-cta {
		font-family: Poppins, 'Noto Sans KR', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		text-transform: capitalize;
		color: $dark;
		padding: 13px;
		&:hover {
			color: $para-color;
		}

		@media (min-width: 1200px) {
			padding-left: 40px;
			padding-right: 40px;
		}
	}

	.nav-logo-placement {
		position: unset;
	}

	.navbar-placeholder-unauth {
		width: calc(74.13px + 94.75px);
		display: none;

		@media (min-width: 1200px) {
			width: calc(128.13px + 94.75px);
		}

		@media (min-width: 1000px) {
			display: block;
		}
	}

	.navbar-placeholder-auth {
		width: 89px;
		display: none;

		@media (min-width: 1000px) {
			display: block;
		}
	}

	.navbar-login-cta {
		display: flex;
		align-items: center;
		font-family: Poppins, 'Noto Sans KR', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: $font-size;
		line-height: 21px;
		text-transform: capitalize;
		color: $dark;
		&:hover {
			color: $para-color;
		}
	}
}

#mobile-navbar {
	padding-left: 0 !important;
	padding-right: 0 !important;
	background-color: $body-back;
	z-index: 2;
	height: 71px !important;
	top: 27.5px;
	.navbar-brand {
		margin-left: 1rem !important;
		margin-top: 5.1px !important;
		margin-bottom: 4px !important;
		z-index: 2;
	}
	.navbar-collapse.collapse.show {
		position: relative;
	}
	.navbar-toggler {
		margin-right: 1rem !important;
		z-index: 2;
	}
	.navbar-nav {
		width: 100%;
	}

	.collapsing {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin: auto;
		height: 100vh !important;
		background-color: $body-back;
		margin-top: -64px !important;
		transition: unset;
	}

	.collapse.show {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin: auto;
		height: 100vh;
		// background-color: $body-back;
		z-index: 1;
		margin-top: -64px;
		background: linear-gradient(to bottom, $body-back 0px, $body-back 78.34px, #f1f1f1 78.34px, #f1f1f1 100%);
	}
}
.navbar-cta {
	font-family: Poppins, 'Noto Sans KR', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	text-transform: capitalize;
	color: $dark;
	padding: 20px;

	&:hover {
		color: $para-color;
	}
}

.navbar-login-cta {
	display: flex;
	align-items: center;
	font-family: Poppins, 'Noto Sans KR', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: $font-size;
	line-height: 21px;
	text-transform: capitalize;
	color: $dark;
	margin: auto !important;

	&:hover {
		color: $para-color;
	}
}

.navbar-signup-cta {
	padding: 20px;
}
.mobile-nav-profile {
	position: absolute;
	bottom: 90px;
	width: 100%;
}

.mobile-nav-cta-wrapper {
	position: absolute;
	bottom: 30px;
	width: 100%;
}

#nav-acc-dropdown {
	padding-top: 9px;
	padding-bottom: 8px;
}
.dropdown-item:active {
	background-color: #f6f6f6;
}

#dashboard-sidebar.collapsed {
	display: none;
}

#dashboard-sidebar.show {
	display: block;
	width: 270px;
	min-height: 100vh;
	@media (max-width: 768px) {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
		width: 100%;
		#sidebar-outer {
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
}
#dashboard-sidebar {
	width: 270px;
	min-height: 100vh;

	.logo {
		margin: 13px 20px 20px 20px;
	}

	ul {
		li {
			padding: 16px 25px;
			margin: 0;
			a,
			div {
				height: 100%;
				text-transform: capitalize;
				div {
					width: 35px;
					height: 25px;
					display: inline-block;
					text-align: center;
					svg {
						color: $primary-color;
						height: 25px;
						width: auto;
					}
				}
				span {
					color: $para-color;
					font-weight: 500;
					margin-left: 16px;
				}
			}
			&:hover {
				padding: 16px 20px 16px 20px;
				border-left: 5px solid $primary-color;
				background-color: $light;
				cursor: pointer;
			}
		}
		a {
			li {
				padding: 16px 25px;
				margin: 0;
				div {
					width: 35px;
					height: 25px;
					display: inline-block;
					text-align: center;
					svg {
						color: $primary-color;
						height: 25px;
						width: auto;

						margin-right: auto;
						margin-left: auto;
					}
				}
				span {
					color: $para-color;
					font-weight: 500;
					margin-left: 16px;
					text-transform: capitalize;
				}
			}
		}
		li.current {
			padding: 16px 20px 16px 20px;
			border-left: 5px solid $primary-color;
			background-color: $light;
		}
		.h-54px {
			height: 54px;
		}
	}
}

#dashboard-navbar {
	background-color: $light;
	.menu {
		cursor: pointer;
		svg {
			height: 24px;
			width: auto;
		}
	}
}
